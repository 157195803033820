<template>
    <div class="d-flex flex-column">
        <h2>{{ entity.pluralTitle }}</h2>
        <schema-table
            ref="schemaTable"
            v-bind="$attrs"
            :entity-key="entityKey"
            :filter="filter"
            class="flex-grow-1">
            <template v-slot:above>
                <schema-filter
                    v-model="filter"
                    class="flex-grow-1"
                    :entity-key="entityKey"
                    v-bind="$attrs" />
            </template>
            <template v-slot:extra-actions="{ item }">
                <v-icon
                    fab
                    color="green"
                    @click.stop="approveEvent(item)">
                    mdi-check
                </v-icon>
                <v-icon
                    fab
                    color="quaternary"
                    @click.stop="removeEvent(item)">
                    mdi-close
                </v-icon>
                <app-button color="secondary" small @click="trustPerson(item)">
                    Trust Person
                </app-button>
            </template>
        </schema-table>
        <message-dialog v-if="approvedMessage" @ok="approvedMessage = false">
            <template v-slot:title>
                Approved
            </template>
            Event "{{ editedItem.name }}" was approved
        </message-dialog>
        <message-dialog v-if="unapprovedMessage" @ok="unapprovedMessage = false">
            <template v-slot:title>
                Not Approved
            </template>
            Event "{{ editedItem.name }}" was unapproved and deleted
        </message-dialog>
    </div>
</template>
<script>
import schemaMixin from "@/features/schemas/mixins/schemaMixin";
import { equal, and } from "@/services/filtering"
import api from "@/features/schemas/services/schemaApi"
export default {
    components: {
        messageDialog: () => import("@/components/MessageDialog.vue")
    },
    mixins: [schemaMixin],
    data() {
        return {
            filter: {},
            approvedMessage: false,
            unapprovedMessage: false,
            editedItem: null
        }
    },
    mounted() {
        this.filter = and([
            equal("isApproved", false, "bool"),
            equal("createdByPerson.isTrustedEventOrganiser", false, "bool")
        ]);
    },
    methods: {
        async approveEvent(item) {
            item.isApproved = true;
            this.editedItem = item;
            this.approvedMessage = true;
            await api.update("event", item);
            await this.$refs.schemaTable.onItemSaved();
        },
        async removeEvent(item) {
            let warningMessage = "Are you sure you want to unapprove this event? " +
                "This will delete the event. This cannot be undone";
            if (!confirm(warningMessage)) {
                return;
            }
            await api.delete("event", item.id)
            this.editedItem = item;
            this.unapprovedMessage = true;
            await this.$refs.schemaTable.onItemSaved();
        },
        async trustPerson(item) {
            let warningMessage = "Are you sure you want to trust this person? " +
                "This will automatically approve all future events created by this person";
            if (!confirm(warningMessage)) {
                return;
            }
            let person = item.createdByPerson;
            person.isTrustedEventOrganiser = true;
            await api.update("person", person);
            await this.$refs.schemaTable.onItemSaved();
        }
    }
};
</script>
